@tailwind base;
@tailwind components;
@tailwind utilities;

@import './range.css';

a,
button,
* [role='button'] {
  @apply focus:outline-none focus:ring;
}

.btn {
  @apply text-blue-800 rounded inline-block;

  &:disabled,
  &.disabled {
    @apply opacity-80 pointer-events-none;
  }

  &.btn-link {
    @apply underline;
  }

  &.btn-primary {
    @apply bg-blue-800 text-white hover:bg-blue-700 px-4 py-2;
  }

  &.btn-secondary {
    @apply bg-blue-100 text-blue-900 hover:bg-blue-200 px-4 py-2;
  }

  &.btn-big {
    @apply text-xl px-8 py-4 rounded-lg;
  }
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-lg text-blue-800;
}

input:not([type='range']) {
  @apply border rounded px-2 py-1 focus:outline-none focus:ring;
}

.card {
  @apply bg-white p-4 rounded-lg shadow;
}
