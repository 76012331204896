input[type='range'] {
  height: 25px;
  -webkit-appearance: none;
  margin: 0 0;
  width: 100%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: rgb(30, 64, 175);
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid rgb(30, 64, 175);
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: rgb(30, 64, 175);
  opacity: 0.8;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: rgb(30, 64, 175);
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: rgb(30, 64, 175);
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid rgb(30, 64, 175);
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: rgb(30, 64, 175);
  opacity: 0.8;
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: rgb(30, 64, 175);
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type='range']::-ms-fill-upper {
  background: rgb(30, 64, 175);
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type='range']::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid rgb(30, 64, 175);
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: rgb(30, 64, 175);
  opacity: 0.8;
  cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
  background: rgb(30, 64, 175);
}
input[type='range']:focus::-ms-fill-upper {
  background: rgb(30, 64, 175);
}

input[type='checkbox'].switch {
  @apply invisible relative cursor-pointer;
  width: 25px;
  height: 18px;

  &::before {
    @apply bg-gray-300 visible block rounded absolute transition-all duration-300 ease-linear;
    content: ' ';
    height: 6px;
    top: 6px;
    left: 0;
    right: 0;
  }
  &::after {
    @apply bg-gray-500 absolute shadow visible block w-3 h-3 rounded-full transition-all duration-300 ease-linear;
    content: ' ';
    top: 3px;
    left: 0;
  }

  &:checked::before {
    @apply bg-blue-500;
  }
  &:checked::after {
    @apply bg-blue-700;
    left: 13px;
  }

  &:disabled {
    @apply opacity-70 cursor-not-allowed;
  }
}
